// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap';
import "@fortawesome/fontawesome-free/js/all";
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("packs/take_test")
require("packs/vap_requests")
require('datatables.net-bs5')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).ready(function() {
  activateClickableTableRows();
  activateChildCreationButtons();

  function activateClickableTableRows() {
    $('.table').on('click', '.clickable-row td', function(){
      if(!$(this).hasClass('unclickable-cell')) {
        window.location = $(this).closest('tr').data('url');
      }
    });
  }

  // Finds the injected template for a form child record, which is stored as var = association + '_fields',
  // replaces the placeholders for the association name and adds the template to the form at location 'target'.
  function activateChildCreationButtons() {
    $('.add_child').click(function() {
      var association = $(this).attr('data-association');
      var target = $(this).attr('target');
      var regexp = new RegExp('new_' + association, 'g');
      var new_id = new Date().getTime();
      var Dest = (target == '') ? $(this).parent() : $(target);

      Dest.append(window[association + '_fields'].replace(regexp, new_id));

      return false;
    });
  }
});

// Hides a child record partial from a form and marks it for removal
$(document).delegate('.remove_child', 'click', function() {
  let confirmText = $(this).data('confirm-text') || "Are you sure?"
  if(confirm(confirmText)) {
    $(this).parent().children('.removable')[0].value = 1;
    $(this).parent().hide();
  
    return false;
  } else {
    return false;
  }
});
