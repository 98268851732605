import $ from 'jquery';

$(document).ready(function() {
  initVapRequestsModalForm();
  initVapRequestAdminIndex();
  initVapRequestTotalsCollapse();
  clearModalPointChangesOnHide();

  function initVapRequestAdminIndex() {
    $('#vap-requests-admin-table').DataTable({
      pageLength: 40,
      lengthChange: false,
      searching: false
    });
  }

  function clearModalPointChangesOnHide() {
    $('.vap-request-form-modal').on('hidden.bs.modal', function (e) {
      var modal = $(this)
      var $pointInputs = modal.find('input[name=sharing_points],input[name=learning_points]');

      $pointInputs.each( function() {
        var oldValue = this.attributes['data-persisted'].value;
        this.value = oldValue;
        renderVapLimitWarning($(this));
      })
    })
  }

  function initVapRequestTotalsCollapse() {
    var initialPadding;
    var collapsableRowsVisible = false;

    $(".row-collapse td").each(function() {
      $(this).wrapInner( "<div class='collapse collapseTotals'></div>" );
      initialPadding = this.style.padding;

      $(this).css({'padding':'0px'});
    });

    $("#showHideVAPTotalsButton").click(function() {
      if(collapsableRowsVisible) {
        _setToggleButtonTextToShow();
        _setTdsToOriginalPadding();
      } else {
        _setToggleButtonTextToHide();
        _setTdsToZeroPadding();
      }

      collapsableRowsVisible = !collapsableRowsVisible;
    });

    function _setTdsToOriginalPadding() {
      $(".row-collapse td").each(function() {
        $(this).animate({
          'padding':initialPadding
        }, 300)
      });
    }

    function _setTdsToZeroPadding() {
      $(".row-collapse td").each(function() {
        $(this).animate({
          'padding':'8px'
        }, 300)
      });
    }

    function _setToggleButtonTextToShow() {
      $("#showHideVAPTotalsButton").text("Show Totals");
    }

    function _setToggleButtonTextToHide() {
      $("#showHideVAPTotalsButton").text("Hide Totals");
    }

  }

  function initVapRequestsModalForm() {
    $('.vap-request-form-modal').each(function(){
      var modal = $(this)
      var $pointInputs = modal.find('input[name=sharing_points],input[name=learning_points]');
      $pointInputs.on('input', function () {
        // Set the required property of the other input to false if this input is not empty.
        $pointInputs.not(this).prop('required', !$(this).val().length);
      });

      // Render vap limit warning, if needed, based on point entry
      $pointInputs.each(function() {
        renderVapLimitWarning($(this));
      });

      $pointInputs.on('keyup', function () {
        // Refresh vap limit warning, if needed, based on point entry
        renderVapLimitWarning($(this));
      });

      var $vapActivityDropdown = modal.find('select[name=vap_activity_id]');
      // Refresh help text on activity change
      $vapActivityDropdown.on('change', function () {
        renderHelpText($(this), modal);
      });

      // Load help text if activity selected on page load (Edit)
      if($vapActivityDropdown.val()) {
        renderHelpText($(this), modal);
      }
    })
  }

  function renderHelpText(dropdown, modal){
    // Set the help text based on the selected activity
    var $selectedOption = dropdown.find(':selected');

    var $descriptionRequirementsContainer = modal.find('#descriptionRequirementsContainer');
    var $whatQualifiesContainer = modal.find('#whatQualifiesContainer');
    var $auditDocumentsContainer = modal.find('#auditDocumentsContainer');

    var infoCircleHtml = '<i class="fas fa-info-circle"></i> ';
    var descriptionRequirements = $selectedOption.data('description-requirements');
    var whatQualifiesHelpText = $selectedOption.data('what-qualifies-help-text');
    var documentsForAudit = $selectedOption.data('documents-for-audit');

    if (descriptionRequirements){
      $descriptionRequirementsContainer.removeClass('d-none').html(infoCircleHtml + descriptionRequirements);
    } else {
      $descriptionRequirementsContainer.addClass('d-none').html('');
    }

    if (whatQualifiesHelpText) {
      $whatQualifiesContainer.removeClass('d-none').html(infoCircleHtml + whatQualifiesHelpText)
    } else {
      $whatQualifiesContainer.addClass('d-none').html('');
    }

    if (documentsForAudit) {
      $auditDocumentsContainer.removeClass('d-none').html(documentsForAudit)
    } else {
      $auditDocumentsContainer.addClass('d-none').html('');
    }
  }

  function renderVapLimitWarning($input){
    var $warningContainer = $input.parent().find('.warning');
    var approvedVaps = parseFloat($input.data('approved'));
    var maxVaps = parseFloat($input.data('max'));
    var pendingVaps = parseFloat($input.data('pending'));
    var persistedVaps = $input.data('persisted');
    var enteredVaps = parseFloat($input.val());
    var submittedVaps = enteredVaps - persistedVaps + approvedVaps + pendingVaps

    if (enteredVaps != 0 && submittedVaps > maxVaps) {
      var certificationName = $input.data('certification-name');
      var requiredVaps = Math.round(maxVaps);
      var pointType = $input.attr('id').split('_')[0];
      var warningText = `Including this request, you are submitting a total number of ${submittedVaps} VAP ${pointType} points for ${certificationName}.
                         Only ${requiredVaps} of points are required. You cannot accumulate more than the required amount, even if your request is approved.`
      $warningContainer.removeClass('d-none').html(`<i class="fas fa-exclamation-circle"></i> ${warningText}`);
    } else {
      $warningContainer.addClass('d-none').html('');
    }
  }
});


